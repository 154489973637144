// import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";

// Vue.use(VueRouter);




const routes = [
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "404",
  //   component: () => import("@/views/404.vue"),
  // },
  {
    path: "/companies/:companyId",
    alias: "/c/:companyId",
    name: "Company Content",
    component: () => import("@/views/Companies.vue"),
  },
  {
    path: "/companies/:companyId/products/:productId",
    alias: "/c/:companyId/p/:productId",
    name: "Product Content",
    component: () => import("@/views/Products.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (to.path === "/") {
    window.location.replace("http://getre.al");
  }
});

export default router;
