import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@authentic-labs/vue-component-library/style.css";
// import "@authentic-labs/vue-component-library/dist/vue-component-library.css"; //TODO FIX THIS
import "@authentic-labs/survey-builder/style.css";
// import "@authentic-labs/survey-builder/dist/survey-builder.css"; //TODO FIX THIS
import amplitude from "amplitude-js";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import { plugin, defaultConfig } from "@formkit/vue";
import "@formkit/themes/genesis";
import mitt from "mitt";
const emitter = mitt();

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY);

Sentry.init({
  Vue,
  dsn: "https://dfec0eea78c9463590bcde67eebeb782@o479985.ingest.sentry.io/5922289",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "content.staging.authenticlabs.com",
        "content.authenticlabs.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


// Vue.config.productionTip = false;


import { BSpinner } from "bootstrap-vue";
Vue.component("b-spinner", BSpinner);

const app = createApp(App);
app.use(plugin, defaultConfig);
app.config.globalProperties.emitter = emitter;
app.provide("emitter", emitter);
app.use(router);
app.mount("#app");
